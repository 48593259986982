import React from "react";
import axios from "axios";

const API_URL_WITH_CREDENTIALS = "https://backend.chetanyewale.com/fetchToken";
const API_URL_WITHOUT_CREDENTIALS = "https://backend.chetanyewale.com/helloWorld";

const Home = () => {
  const fetchData = async (url, withCredentials) => {
    try {
      const response = await axios.get(url, {
        withCredentials: withCredentials,
      });
      console.log(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  return (
    <div>
      <button onClick={() => fetchData(API_URL_WITH_CREDENTIALS, true)}>
        Fetch Data with Credentials
      </button>
      <button onClick={() => fetchData(API_URL_WITHOUT_CREDENTIALS, true)}>
        Fetch Data without Credentials
      </button>
    </div>
  );
};

export default Home;
